import {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {UserContext} from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
const Register = () => {
    const {registerUser, wait} = useContext(UserContext);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [formData, setFormData] = useState({
        name:'',
        email:'',
        password:'',
        address:'',
        city:'',
        state:'',
        zipcode:'',
        country:''
        
    });

   

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    
    const navigate = useNavigate();
    const submitForm = async (e) => {
        e.preventDefault();
        navigate('/table');
        

        if(!Object.values(formData).every(val => val.trim() !== '')){
            setSuccessMsg(false);
            setErrMsg('Please Fill in all Required Fields!');
            return;
        }

        const data = await registerUser(formData);
        if(data.success){
            e.target.reset();
            setSuccessMsg('You have successfully registered.');
            setErrMsg(false);
            
        }
        else if(!data.success && data.message){
            setSuccessMsg(false);
            setErrMsg(data.message);
        }
        
    }
    

    return (
        <div className="myform">
            <h2>Sign Up</h2>
            <form onSubmit={submitForm}>
                <label htmlFor="name">Name:</label>
                <input type="text" name="name" onChange={onChangeInput} placeholder="Your name" id="name" value={formData.name} required />
                <label htmlFor="email">Email:</label>
                <input type="email" name="email" onChange={onChangeInput} placeholder="Your email" id="email" value={formData.email} required />
                <label htmlFor="password">Password:</label>
                <input type="password" name="password" onChange={onChangeInput} placeholder="New password" id="password" value={formData.password} required />
                <label htmlFor="name">Address:</label>
                <input type="text" name="address" onChange={onChangeInput} placeholder="Your address" id="address" value={formData.address}  />
                <label htmlFor="email">City:</label>
                <input type="text" name="city" onChange={onChangeInput} placeholder="Your city" id="city" value={formData.city}  />
                <label htmlFor="password">State:</label>
                <input type="text" name="state" onChange={onChangeInput} placeholder="Your state" id="state" value={formData.state}  />
                <label htmlFor="name">Zipcode:</label>
                <input type="text" name="zipcode" onChange={onChangeInput} placeholder="Your zipcode" id="zipcode" value={formData.zipcode}  />
                <label htmlFor="email">Country:</label>
                <input type="text" name="country" onChange={onChangeInput} placeholder="Your country" id="country" value={formData.country} />
               
                {successMsg && <div className="success-msg">{successMsg}</div>}
                {errMsg && <div className="err-msg">{errMsg}</div>}
                <button type="submit" disabled={wait}>Sign Up</button>
                <div className="bottom-link"><Link to="/login">Login</Link></div>
                <div className="bottom-link"><Link to="/table">Register User</Link></div>
            </form>
        </div>
    )
}

export default Register;
