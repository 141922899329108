import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CustomizedTables() {

  const url = "https://robertg148.sg-host.com/api_demo/read.php"
  const [data, setData] = useState([])
 

  
  useEffect(() => {
    getEmployee() 
  }, [])

  const getEmployee=()=>{
    fetch(url).then(resp=>resp.json())
    .then(resp=>setData(resp.body ))
  }

 //deleting a user
 const handleDelete = (id) => {
  const confirm = window.confirm("Are you sure, you want to delete this row", id)
  if (confirm) {
    fetch("https://robertg148.sg-host.com/api_demo/delete.php?id=" + `${id}`, { method: "GET" }).then(resp => resp.json()).then(resp => getEmployee())

  }
}
  
return (
    <div>
    <TableContainer component={Paper}>
      <Paper sx={{ width: '100%', mb: 2 }}></Paper>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        
        <TableHead>
        <TableRow>
        <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell align="right">Name</StyledTableCell>
            <StyledTableCell align="right">Email</StyledTableCell>
            <StyledTableCell align="center">Password</StyledTableCell>
            <StyledTableCell align="right">Address</StyledTableCell>
            <StyledTableCell align="right">City</StyledTableCell>
            <StyledTableCell align="right">State</StyledTableCell>
            <StyledTableCell align="right">ZipCode</StyledTableCell>
            <StyledTableCell align="right">Country</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            
            
            
          </TableRow>
        </TableHead>
        <TableBody>
          
          
          {data.map((data ,index) => (
            <StyledTableRow key={data.name} >
              
              <StyledTableCell component="th" scope="row" >
              
                {data.id}
              </StyledTableCell>
              <StyledTableCell align="right">{data.name}</StyledTableCell>
              <StyledTableCell align="right">{data.email}</StyledTableCell>
              <StyledTableCell align="right">{data.password}</StyledTableCell>
              <StyledTableCell align="right">{data.address}</StyledTableCell>
              <StyledTableCell align="right">{data.city}</StyledTableCell>
              <StyledTableCell align="right">{data.state}</StyledTableCell>
              <StyledTableCell align="right">{data.zipcode}</StyledTableCell>
              <StyledTableCell align="right">{data.country}</StyledTableCell>
              <Stack direction="row" spacing={2}>
              
              <Button variant="outlined" onClick={() => handleDelete(data.id)}  startIcon={<DeleteIcon />}>Delete</Button>
              </Stack>
            </StyledTableRow>
          ))}
          
        </TableBody>
        </Table>
        
        
    </TableContainer>
    <div className="bottom-link"><Link to="/boaters">Boaters Register</Link></div>
                <div className="bottom-link"><Link to="/boaterstable">Boaters Table</Link></div>
                <div className="bottom-link"><Link to="/contactus">Contact Us</Link></div>
    </div>
  );
}
