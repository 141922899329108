import React, { useState } from "react";
import axios from 'axios'
import { Grid, Paper, Avatar, Typography, TextField, Button } from '@material-ui/core'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {Link} from 'react-router-dom'


const BoatersRegister = () => {

    const [user, setUser] = useState({
        OwnersName: "",
        BoatName: "",
        Manufacturer_Model_EngineSerial: "",
        Length:"",
        Beam: "",
        Engine_Manufacturer_Model: "",
        Check_what_applies_to_you: "",
        Registration_Number:"",
        Trailer_Plate: "",
        Key_Location: "",
        Same_Location_After_Completion: "",
        Email_Address:"",
        Address:"",
        Telephone:"",
        Other: "",
        Haul_Out_Date: "",
        Boat_Location: ""
        
      });
     
      const { OwnersName, BoatName, Manufacturer_Model_EngineSerial, Length, Beam, Engine_Manufacturer_Model, Check_what_applies_to_you,
        Registration_Number, Trailer_Plate, Key_Location, Same_Location_After_Completion, Email_Address, Address, Telephone,Other,
        Haul_Out_Date, Boat_Location } = user;
       
      const onInputChange = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
      };
       
      const onSubmit = async e => {
        e.preventDefault();
        
        await axios.post("https://robertg148.sg-host.com/Boaters-api/register.php",user);
        alert('Data Inserted');
        
        // history.push("/");
      };


    const paperStyle = { padding: '30px 20px', width: 700, margin: "20px auto" }
    const headerStyle = { margin: 0 }
    const avatarStyle = { backgroundColor: '#1bbd7e' }
    const marginTop = { marginTop:22 }
    return (
        <div>
        <Grid>
            <Paper elevation={20} style={paperStyle}>
                <Grid align='center'>
                    <Avatar style={avatarStyle}>
                        <AddCircleOutlineOutlinedIcon />
                    </Avatar>
                    <h2 style={headerStyle}>SPRING COMISSIONING FORM 2022</h2>
                    <Typography variant='caption' gutterBottom>Please fill this form  !</Typography>
                </Grid>
                <form onSubmit={e => onSubmit(e)}>
                    <TextField fullWidth label='OwnersName' placeholder="Enter your name" name="OwnersName" value={OwnersName}  onChange={e => onInputChange(e)} required />
                    <TextField fullWidth label='BoatName' placeholder="Enter BoatName" name="BoatName" value={BoatName}  onChange={e => onInputChange(e)} required />
                    <TextField fullWidth label='Manufacturer Model EngineSerial' placeholder="Enter Manufacturer Model EngineSerial" name="Manufacturer_Model_EngineSerial" value={Manufacturer_Model_EngineSerial}  onChange={e => onInputChange(e)} required />
                    <TextField fullWidth label='Length' placeholder="Enter Length" name="Length" value={Length}  onChange={e => onInputChange(e)} required />
                    <TextField fullWidth label='Beam' placeholder="Enter Beam" name="Beam" value={Beam}  onChange={e => onInputChange(e)} required />
                    <TextField fullWidth label='Engine Manufacturer Model' placeholder="Enter Engine Manufacturer Model" name="Engine_Manufacturer_Model" value={Engine_Manufacturer_Model}  onChange={e => onInputChange(e)} required />
                    <FormControl component="fieldset" style={marginTop}>
                        <FormLabel component="legend">Check what applies to you</FormLabel>
                        <RadioGroup aria-label="Check what applies to you" name="Check_what_applies_to_you" style={{ display: 'initial' }} value={Check_what_applies_to_you}  onChange={e => onInputChange(e)} required>
                            <FormControlLabel value="Power" control={<Radio />} label="Power"  />
                            <FormControlLabel value="Sail" control={<Radio />} label="Sail" />
                            <FormControlLabel value="Gas" control={<Radio />} label="Gas"  />
                            <FormControlLabel value="Diesel" control={<Radio />} label="Diesel"  />
                        </RadioGroup>
                    </FormControl>
                    <TextField fullWidth label='Registration Number' placeholder="Enter your Registration Number" name="Registration_Number" value={Registration_Number}  onChange={e => onInputChange(e)} required />
                    <TextField fullWidth label='Trailer Plate' placeholder="Enter Trailer Plate" name="Trailer_Plate" value={Trailer_Plate}  onChange={e => onInputChange(e)} required/>
                    <TextField fullWidth label='Key Location' placeholder="Enter Key Location" name="Key_Location" value={Key_Location}  onChange={e => onInputChange(e)} required/>
                    <FormControl component="fieldset" style={marginTop}>
                        <FormLabel component="legend">Same Location After Completion</FormLabel>
                        <RadioGroup aria-label="Same Location After Completion" name="Same_Location_After_Completion" style={{ display: 'initial' }} value={Same_Location_After_Completion}  onChange={e => onInputChange(e)} required>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes"  />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                            
                        </RadioGroup>
                    </FormControl>
                    <TextField fullWidth label='Email Address' placeholder="Enter Email Address" name="Email_Address" value={Email_Address}  onChange={e => onInputChange(e)} required/>
                    <TextField fullWidth label='Address' placeholder="Enter Address" name="Address" value={Address}  onChange={e => onInputChange(e)} required/>
                    <TextField fullWidth label='Telephone' placeholder="Enter Telephone" name="Telephone" value={Telephone}  onChange={e => onInputChange(e)} required />
                    <TextField fullWidth label='Other' placeholder="Enter Other" name="Other" value={Other}  onChange={e => onInputChange(e)} required/>
                    <TextField fullWidth label='Haul Out Date' placeholder="Enter Haul Out Date" name="Haul_Out_Date" value={Haul_Out_Date}  onChange={e => onInputChange(e)} required/>
                    <FormControl component="fieldset" style={marginTop}>
                        <FormLabel component="legend">Boat Location</FormLabel>
                        <RadioGroup aria-label="Boat Location" name="Boat_Location" style={{ display: 'initial' }} value={Boat_Location}  onChange={e => onInputChange(e)} required>
                            <FormControlLabel value="North Yard" control={<Radio />} label="North Yard"  />
                            <FormControlLabel value="South yard" control={<Radio />} label="South yard"  />
                            <FormControlLabel value="Other" control={<Radio />} label="Other"  />
                           
                        </RadioGroup>
                    </FormControl>
                    <Button type='submit' variant='contained' color='primary'>Add Boaters</Button>
                </form>
            </Paper>
        </Grid>
        
                <div className="bottom-link"><Link to="/boaterstable">Boater User</Link></div>
        </div>
    )
}

export default BoatersRegister;