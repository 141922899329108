import {useContext} from 'react'
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {UserContext} from './context/UserContext';
import Login from './components/Login';
import Register from './components/Register';
import Home from './components/Home';
import CustomizedTables from './components/Table';
import BoatersRegister from './components/boatersregister';
import BoatersTables from './components/boaterstable';
import MiniDrawer from './components/Appbar';
import ContactUs from './components/contactForm';



function App() {

  const {user} = useContext(UserContext); 
  
  
 
  return (
    <div className="container">
      <MiniDrawer />
      <BrowserRouter>
          <Routes>
            { user && <Route path="/" element={<Home/>} /> }
            {!user && (
              <>
              
              <Route path="/login" element={<Login/>} />
              <Route path="/signup" element={<Register/>} />
              <Route path="/table" element={<CustomizedTables />} />
              <Route path="/boaters" element={<BoatersRegister />} />
              <Route path="/boaterstable" element={<BoatersTables />} />
              <Route path="/contactus" element={<ContactUs />} />
              </>
            )}
            <Route path="*" element={<Navigate to={user ? '/':'/login'} />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}
export default App;