
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function BoatersTables() {

  const url = "https://robertg148.sg-host.com/api_boaters/read.php"
  const [data, setData] = useState([])
 

  
  useEffect(() => {
    getEmployee() 
  }, [])

  const getEmployee=()=>{
    fetch(url).then(resp=>resp.json())
    .then(resp=>setData(resp.body ))
  }

 //deleting a user
 const handleDelete = (id) => {
  const confirm = window.confirm("Are you sure, you want to delete this row", id)
  if (confirm) {
    fetch("https://robertg148.sg-host.com/api_boaters/delete.php?id=" + `${id}`, { method: "GET" }).then(resp => resp.json()).then(resp => getEmployee())

  }
}
  
return (
    <div>
    <TableContainer component={Paper}>
      <Paper sx={{ width: '100%', mb: 2 }}></Paper>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        
        <TableHead>
        <TableRow>
        <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell align="right">OwnersName</StyledTableCell>
            <StyledTableCell align="right">Date Submitted</StyledTableCell>
            <StyledTableCell align="center">BoatName</StyledTableCell>
            <StyledTableCell align="right">Manufacturer Model EngineSerial</StyledTableCell>
            <StyledTableCell align="right">Length</StyledTableCell>
            <StyledTableCell align="right">Beam</StyledTableCell>
            <StyledTableCell align="right">Engine Manufacturer Model</StyledTableCell>
            <StyledTableCell align="right">Check what applies to you</StyledTableCell>
            <StyledTableCell align="center">Registration Number</StyledTableCell>
            <StyledTableCell align="right">Trailer Plate</StyledTableCell>
            <StyledTableCell align="right">Key Location</StyledTableCell>
            <StyledTableCell align="right">Same Location After Completion</StyledTableCell>
            <StyledTableCell align="right">Email Address</StyledTableCell>
            <StyledTableCell align="right">Address</StyledTableCell>
            <StyledTableCell align="right">Telephone</StyledTableCell>
            <StyledTableCell align="right">Other</StyledTableCell>
            <StyledTableCell align="right">Haul Out Date</StyledTableCell>
            <StyledTableCell align="right">Boat Location</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
            
            
            
          </TableRow>
        </TableHead>
        <TableBody>
          
          
          {data.map((data ,index) => (
            <StyledTableRow key={data.name} >
              
              <StyledTableCell component="th" scope="row" >
              
                {data.id}
              </StyledTableCell>
              <StyledTableCell align="right">{data.OwnersName}</StyledTableCell>
              <StyledTableCell align="right">{data.Date_Submitted}</StyledTableCell>
              <StyledTableCell align="right">{data.BoatName}</StyledTableCell>
              <StyledTableCell align="right">{data.Manufacturer_Model_EngineSerial}</StyledTableCell>
              <StyledTableCell align="right">{data.Length}</StyledTableCell>
              <StyledTableCell align="right">{data.Beam}</StyledTableCell>
              <StyledTableCell align="right">{data.Engine_Manufacturer_Model}</StyledTableCell>
              <StyledTableCell align="right">{data.Check_what_applies_to_you}</StyledTableCell>
              <StyledTableCell align="right">{data.Registration_Number}</StyledTableCell>
              <StyledTableCell align="right">{data.Trailer_Plate}</StyledTableCell>
              <StyledTableCell align="right">{data.Key_Location}</StyledTableCell>
              <StyledTableCell align="right">{data.Same_Location_After_Completion}</StyledTableCell>
              <StyledTableCell align="right">{data.Email_Address}</StyledTableCell>
              <StyledTableCell align="right">{data.Address}</StyledTableCell>
              <StyledTableCell align="right">{data.Telephone}</StyledTableCell>
              <StyledTableCell align="right">{data.Other}</StyledTableCell>
              <StyledTableCell align="right">{data.Haul_Out_Date}</StyledTableCell>
              <StyledTableCell align="right">{data.Boat_Location}</StyledTableCell>
              <Stack direction="row" spacing={2}>
              
              <Button variant="outlined" onClick={() => handleDelete(data.id)}  startIcon={<DeleteIcon />}>Delete</Button>
              </Stack>
            </StyledTableRow>
          ))}
          
        </TableBody>
        </Table>
        
        
    </TableContainer>
    <div className="bottom-link"><Link to="/login">Login</Link></div>
        <div className="bottom-link"><Link to="/boaters">Boaters Register </Link></div>
    </div>
  );
}
